<template>
    <div class="login-container">
        <div class="box">
            <div class="logo"><img alt="logo" src="../assets/logo.png" /></div>
            <div class="login-form">
                <h1>
                    <Icon type="md-log-in" color="#00B2A6" />&nbsp;
                    <span>欢迎登录&nbsp;&nbsp;&nbsp;企业微信导购助手CMS</span>
                </h1>
                <Form ref="formInline" :model="form" :rules="rule" class="iv-form">
                    <FormItem prop="username">
                        <Input type="text" v-model="form.username" @keyup.enter.native="handleSubmit" placeholder="登录邮箱">
                            <Icon type="md-mail" color="#00B2A6" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <Input type="password" v-model="form.password" @keyup.enter.native="handleSubmit" placeholder="登录密码">
                            <Icon type="md-lock" color="#00B2A6" slot="prepend"/>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <Button :loading="loading" long @click="handleSubmit" style="background-color: #00B2A6; color: #fff">登录</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
        <p>Copyright 2019. All right reserved. 沪ICP备17005510号-2</p>
    </div>
</template>
<script>
import qs from "qs";

export default {
    data () {
        return {
            loading: false,
            form: {
                username: '',
                password: ''
            },
            rule: {
                username: [
                    { required: true, message: '请输入邮箱',  trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码.', trigger: 'blur' },
                    { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.formInline.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('login', qs.stringify(this.form))
                    .then(() => {
                        this.$router.push("/");
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .login-container {
        height: 100%;
        background: center center url('/login-bg.jpg') no-repeat;
        background-size: cover;
        overflow: hidden;
    }

    .box {
        width: 300px;
        margin: 160px auto 0 auto;
    }

    .logo {
        width: 150px;
        margin: 0 auto 56px auto;

        img {
            display: block;
            width: 100%;
        }
    }

    .login-form {
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;

        h1 {
            font-size: 16px;
            color: #323233;
            margin: 0;
            height: 46px;
            line-height: 46px;
            border-bottom: 1px solid #EBEDF0;
            padding-left: 13px;
        }

        .iv-form {
            padding: 28px 20px 8px 20px;
        }
    }

    p {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 24px;
        height: 18px;
        font-size: 13px;
        color: #C8C9CC;
        text-align: center;
    }
</style>